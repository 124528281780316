// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-all-news-all-news-js": () => import("./../../../src/components/pages/allNews/AllNews.js" /* webpackChunkName: "component---src-components-pages-all-news-all-news-js" */),
  "component---src-components-pages-news-page-single-news-page-js": () => import("./../../../src/components/pages/newsPage/SingleNewsPage.js" /* webpackChunkName: "component---src-components-pages-news-page-single-news-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */)
}

